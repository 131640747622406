import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import '../styles/style.scss';

import { useStaticQuery, graphql } from "gatsby";

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
  query {
    notFound: file(relativePath: { eq: "404.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1900, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`)

  return (
    <Layout>
      <SEO title="404: Página no encontrada" />
      <section id="notfound" className="section hero has-text-centered">
        <h1 className="title">La página que buscas no está en nuestro nido</h1>
        <p className="subtitle has-margin-bottom-40">Puede que este huevo se haya caido del nido, o puede que ya se haya ido volando por su cuenta.</p>

        <Img fluid={data.notFound.childImageSharp.fluid} style={{height: '480px', width: 'auto'}} objectFit="cover" alt="Página no encontrada." />

        <div className="column">
          <Link className="button is-primary" to="/">Volver a la home</Link>
        </div>
      </section>
    </Layout>
  );
}

export default NotFoundPage
